import './alpine';

import jQuery from 'jquery';
import assignDotNetReference from "./blazor/assignDotNetReference.js";
import callDotNet from "./blazor/callDotNet.js";
import callMethodOnElement from "./blazor/callMethodOnElement.js";
import triggerEvent from "./blazor/triggerEvent.js";
import updateAlpineModel from "./blazor/updateAlpineModel.js";
import updateElementHtml from "./blazor/updateElementHtml.js";
import * as keybind from './events/keybind.js';
import debounce from "./utilities/debounce.js";
import delay from "./utilities/delay.js";
import downloadFileStream from "./utilities/downloadFileStream.js";
import findClosestScrollContainer from "./utilities/findClosestScrollContainer.js";
import focus from "./utilities/focus.js";
import isElementInView from "./utilities/isElementInView.js";
import openFileInNewTab from "./utilities/openFileInNewTab.js";
import scrollToId from "./utilities/scrollToId.js";
import setTitle from "./utilities/setTitle.js";

window.$ = jQuery;
window.jQuery = jQuery;

window.ra = window.ra || {};

// Blazor
window.ra.assignDotNetReference = assignDotNetReference;
window.ra.callDotNet = callDotNet;
window.ra.callMethodOnElement = callMethodOnElement;
window.ra.triggerEvent = triggerEvent;
window.ra.updateAlpineModel = updateAlpineModel;
window.ra.updateElementHtml = updateElementHtml;

// Events
window.ra.listenToKeybind = keybind.listenToKeybind;
window.ra.unlistenToKeybind = keybind.unlistenToKeybind;

// Utilities
window.ra.debounce = debounce;
window.ra.delay = delay;
window.ra.downloadFileStream = downloadFileStream;
window.ra.findClosestScrollContainer = findClosestScrollContainer;
window.ra.focus = focus;
window.ra.isElementInView = isElementInView;
window.ra.openFileInNewTab = openFileInNewTab;
window.ra.scrollToId = scrollToId;
window.ra.setTitle = setTitle;

const script = document.createElement('script');
const hostname = window.location.hostname.toLowerCase();
if (hostname == 'localhost' || hostname == '127.0.0.1' || hostname.endsWith('-local.riskalive.com') || hostname.endsWith('-dev.riskalive.com')) {
    script.setAttribute('src', 'https://lorax-dev.riskalive.com/riskalive.js');
} else if (hostname.endsWith('-uat.riskalive.com')) {
    script.setAttribute('src', 'https://lorax-uat.riskalive.com/riskalive.js');
} else if (hostname.endsWith('-staging.riskalive.com')) {
    script.setAttribute('src', 'https://lorax-staging.riskalive.com/riskalive.js');
} else {
    script.setAttribute('src', 'https://lorax.riskalive.com/riskalive.js');
}

document.head.appendChild(script);

const startBlazor = function () {
    setTimeout(function () {
        if (!window.riskalive) {
            console.log('Waiting for riskalive.js script.');
            startBlazor();
            return;
        }

        Blazor.start();
    }, 50);
};

startBlazor();

setTimeout(function () {
    if (document.querySelector('.initial-loader')) {
        document.querySelector('.initial-loader-content').style.display = "none";
        document.getElementById('app_failed').style.display = "block";
    }
}, 20000);
