export default async (element, method, ...args) => {
    if (!element._dotNetReference) {
        console.warn(`Calling ${method}(). The dotNetReference is missing on the element.`);
        return;
    }

    return await element._dotNetReference.invokeMethodAsync.apply(element._dotNetReference, [
        method,
        ...args,
    ]);
};
