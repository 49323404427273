import Alpine from 'alpinejs';
import Turndown from 'turndown';
import jQuery from "jquery";
import Quill from "quill/core";

import Keyboard from "quill/modules/keyboard";
import Toolbar from "quill/modules/toolbar";

import Bubble from "quill/themes/bubble";
import Snow from "quill/themes/snow";

import Bold from "quill/formats/bold";
import Italic from "quill/formats/italic";
import Strike from "quill/formats/strike";
import Link from "quill/formats/link";

import callDotNet from "../blazor/callDotNet.js";
import updateAlpineModel from "../blazor/updateAlpineModel.js";
import delay from "../utilities/delay.js";
import removeHtmlComments from "../utilities/removeHtmlComments.js";
import debounce from "../utilities/debounce.js";
import escapeRegex from "../utilities/escapeRegex.js";

Quill.register({
    "modules/keyboard": Keyboard,
    "modules/toolbar": Toolbar,
    "themes/bubble": Bubble,
    "themes/snow": Snow,
    "formats/bold": Bold,
    "formats/italic": Italic,
    "formats/strike": Strike,
    "formats/link": Link,
});

Alpine.directive('quill', (element, {expression}, {cleanup, effect, evaluateLater}) => {
    const getHighlight = evaluateLater("highlight");
    const getPlaceholder = evaluateLater("placeholder");
    const getValue = evaluateLater("value");

    const $element = jQuery(element);

    const isPlaceholder = () => {
        return element.classList.contains("text-neutral-400");
    }

    /**
     * Removes the placeholder styling, so it looks like a field with a value.
     */
    const removePlaceholder = () => {
        if (!isPlaceholder()) {
            return;
        }

        getValue(value => {
            element.classList.remove("text-neutral-400", "italic", "text-sm");
            element.innerHTML = value || '';
        });
    };

    /**
     * If the field has a value, this method will remove the placeholder styling.
     * If the field does not have a value, this method will add a placeholder styling and show the placeholder label.
     */
    const togglePlaceholder = () => {
        getValue(value => {
            if (expression === 'snow') {
                return;
            }

            if (!value) {
                getPlaceholder(placeholder => {
                    element.classList.add("text-neutral-400", "italic", "text-sm");
                    element.innerHTML = placeholder;
                });
                return;
            }

            removePlaceholder();
        });
    };

    /**
     * Toggles the highlight styling where the text matches the highlight value.
     */
    const toggleHighlight = () => {
        getHighlight(highlight => {
            if (isPlaceholder() || isFocused() || expression === 'snow') {
                return;
            }

            if (highlight) {
                var regex = new RegExp('(' + escapeRegex(highlight) + ')', 'gi');
                element.innerHTML = element.innerHTML.replace(regex, '<span class="bg-yellow-200">$1</span>');
            } else {
                $element.find('.bg-yellow-200').contents().unwrap();
            }
        });
    };

    /**
     * Gets a value indicating whether the quill editor is focused or not. We are also checking if the focus is on the toolbar.
     */
    const isFocused = () => {
        if (!element._quill) {
            return false;
        }

        if (element._quill.hasFocus()) {
            return true;
        }

        return !$element.find('.ra-quill .ql-tooltip').hasClass('ql-hidden');
    };

    /**
     * Method that updates the blazor value.
     */
    const updateBlazor = () => {
        const turndown = new Turndown({
            headingStyle: 'atx',
            hr: '---',
            bulletListMarker: '-',
            codeBlockStyle: 'fenced',
        });
        turndown.addRule('strikethrough', {
            filter: ['del', 's', 'strike'],
            replacement: function (content) {
                return '~~' + content + '~~'
            }
        });
        const markdown = turndown.turndown(element._quill.getSemanticHTML());
        callDotNet(element, "OnBlur", markdown);
    };
    const debounceUpdate = debounce(updateBlazor);

    /**
     * Loop to detect when the editor is closed. We are checking that the toolbar is hidden and that the editor does not have focus to close the editor. Then closes the rich text editor by removing additional elements and events generated by Quill.
     */
    const closeBubble = async () => {
        await delay(500);

        if (isFocused()) {
            closeBubble();
            return;
        }

        if (!element._quill) {
            return;
        }

        updateBlazor();

        let html = element._quill.getSemanticHTML();
        html = html.replace("<a ", '<a target="_blank" onclick="event.stopPropagation();" contenteditable="false" ');
        html = html.replace("del>", 's>');
        element.innerHTML = html;
        element._quill = undefined;

        element.setAttribute("contenteditable", true);
        element.addEventListener('focus', focus);
        togglePlaceholder();
        toggleHighlight();
    };

    /**
     * Gets a value indicating whether the quill editor is initialized and open (ready).
     */
    const isOpened = () => {
        return !!element._quill;
    }

    /**
     * Opens the quill editor.
     */
    const openQuill = async () => {
        await delay(100);
        if (isOpened()) {
            return;
        }

        const selection = window.getSelection();
        let cursorPosition = 0;
        if (selection.rangeCount > 0) {
            const range = selection.getRangeAt(0);
            const clonedRange = range.cloneRange();
            clonedRange.selectNodeContents(element);
            clonedRange.setEnd(range.endContainer, range.endOffset);
            cursorPosition = clonedRange.toString().length;
        }

        element.setAttribute("contenteditable", false);

        getPlaceholder(placeholder => {
            $element.wrapInner('<div class="ra-quill min-w-40"></div>');
            const quillElement = $element.children('.ra-quill').get(0);
            element._quill = new Quill(quillElement, {
                bounds: element,
                theme: expression,
                modules: {
                    keyboard: {
                        bindings: {
                            enter: {
                                key: 'Enter',
                                shiftKey: false,
                                handler: function () {
                                    element._quill.blur();

                                    const $formButton = $element.closest('form').find('button[type=submit]');
                                    if ($formButton.length > 0) {
                                        $formButton.get(0).click();
                                    }

                                    return false;
                                }
                            },
                            tab: {
                                key: 'Tab',
                                handler: function () {
                                    return true;
                                }
                            },
                        }
                    },
                    toolbar: [
                        ['bold', 'italic', 'strike'],
                        ['link'],
                    ]
                },
                placeholder: placeholder,
            });
            element._quill.setSelection(cursorPosition, 0);

            if (expression === 'bubble') {
                closeBubble();
            } else if (expression === 'snow') {
                element._quill.on('selection-change', (range) => {
                    if (!range) {
                        updateBlazor();
                    }
                });
                element._quill.on('text-change', debounceUpdate);
            }
        });
    };

    /**
     * Attach events to the field and removes the placeholder styling.
     */
    const focus = async () => {
        removePlaceholder();
        openQuill();
        element.removeEventListener('focus', focus);
    };

    element.innerHTML = removeHtmlComments(element.innerHTML);
    updateAlpineModel(element, element.innerHTML);

    // Only add event listeners if the field should be editable.
    if (expression === 'bubble') {
        togglePlaceholder();
        element.setAttribute("contenteditable", true);
        element.addEventListener('focus', focus);
    } else if (expression === 'snow') {
        openQuill();
    } else if (expression === 'readonly') {
        togglePlaceholder();
    }

    // Update the element html when the value is changed by javascript code.
    if (expression !== 'snow') {
        effect(() => {
            getValue(value => {
                if (isFocused()) {
                    return;
                }

                element.innerHTML = value;
                togglePlaceholder();
            });
        });

        effect(toggleHighlight);
    }

    // Cleanup all event listeners when the field is removed from the DOM.
    cleanup(() => {
        element.removeEventListener('focus', focus);
    });
});
