import Alpine from 'alpinejs';
import callDotNet from "../blazor/callDotNet.js";
import updateAlpineModel from "../blazor/updateAlpineModel.js";

export const CONTENTEDITABLE_CLASSES = '-m-1 border-none focus:outline-none leading-5 min-w-4 p-1 rounded-lg whitespace-pre-wrap duration-200 focus:ring-2 focus:ring-offset-0 focus:ring-cyan-400 transition-colors hover:bg-black/[.1]';

Alpine.directive('contenteditable', (element, {expression}, {cleanup, effect, evaluateLater}) => {
    const getValue = evaluateLater("value");
    const getPlaceholder = evaluateLater("placeholder");
    const getPlaceholderClass = evaluateLater("placeholderClass");
    const type = expression;

    switch (type) {
        case 'readonly':
        case 'text':
        case 'decimal':
            break;
        default:
            console.warn("The contenteditable directive received an invalid type parameter.");
    }

    /**
     * Removes the placeholder styling so it looks like a field with a value.
     */
    const removePlaceholder = () => {
        getValue(value => {
            getPlaceholderClass(placeholderClass => {
                element.classList.remove(placeholderClass, "italic", "text-sm");
                element.innerHTML = value || '';
            });
        });
    };

    /**
     * If the field has a value, this method will remove the placeholder styling.
     * If the field does not have a value, this method will add a placeholder styling and show the placeholder label.
     */
    const togglePlaceholder = () => {
        getValue(value => {
            if (!value) {
                getPlaceholder(placeholder => {
                    getPlaceholderClass(placeholderClass => {
                        element.classList.add(placeholderClass, "italic", "text-sm");
                        element.innerHTML = placeholder;
                    });
                });
                return;
            }

            removePlaceholder();
        });
    };

    /**
     * When an input happens on the element, we need to make sure that the underlying alpine model is updated.
     */
    const input = () => {
        updateAlpineModel(element, element.innerHTML);
    };

    /**
     * Custom implementation of the paste event to remove any <span> and other HTML elements. that would be included in the default implementation of the paste.
     */
    const paste = (event) => {
        event.preventDefault();

        const paste = (event.clipboardData || window.clipboardData).getData("text");
        const selection = window.getSelection();
        if (!selection.rangeCount) return;
        selection.deleteFromDocument();
        selection.getRangeAt(0).insertNode(document.createTextNode(paste));
        selection.collapseToEnd();

        element.dispatchEvent(new Event('input'));
    };

    /**
     * Handle special keydown cases.
     * Enter - Does not create a new line and should trigger a blur event so that the Blazor code can then update the value.
     * ArrowUp - For decimal fields, we want to increment the value by 1.
     * ArrowDown - For decimal fields, we want to decrement the value by 1.
     * For decimal fields we want to avoid any keydown that are not numbers, or periods, or commas.
     */
    const keydown = (event) => {
        if (event.key === "Enter") {
            event.preventDefault();
            element.blur();
            return;
        }

        if (type === 'decimal') {
            if (event.key === 'ArrowUp' || event.key === 'ArrowDown') {
                getValue(value => {
                    const $data = Alpine.$data(element);
                    if (event.key === 'ArrowUp') {
                        $data.value = `${parseFloat(value) + 1}`;
                    } else if (event.key === 'ArrowDown') {
                        $data.value = `${parseFloat(value) - 1}`;
                    } else {
                        $data.value = '0';
                    }
                });
            }

            if (event.key !== ',' && event.key !== '.' && event.key.length === 1 && isNaN(event.key)) {
                event.preventDefault();
            }
        }

        event.stopPropagation();
    };

    /**
     * When the field is blurred, we want to remove all attached event handlers in an attempt to keep the page as light as possible.
     * We also want to notify Blazor that the field is blurred and passing the final field value.
     */
    const blur = () => {
        element.style.minWidth = null;
        togglePlaceholder();

        element.removeEventListener('blur', blur);
        element.removeEventListener('input', input);
        element.removeEventListener('paste', paste);
        element.removeEventListener('keydown', keydown);

        getValue(value => {
            callDotNet(element, "OnBlur", value);
        });
    };

    /**
     * Attach events to the field and removes the placeholder styling.
     */
    const focus = () => {
        const rect = element.getBoundingClientRect();
        element.style.minWidth = rect.width + 'px';
        removePlaceholder();

        element.addEventListener('blur', blur);
        element.addEventListener('input', input);
        element.addEventListener('paste', paste);
        element.addEventListener('keydown', keydown);
    };

    updateAlpineModel(element, element.innerHTML);
    togglePlaceholder();

    element.raContentEditableInitialize = (type) => {
        element.removeEventListener('focus', focus);

        // Only add event listeners if the field should be editable.
        if (type !== 'readonly') {
            element.className = CONTENTEDITABLE_CLASSES;
            element.tabIndex = 0;
            element.setAttribute("contenteditable", true);
            element.addEventListener('focus', focus);
        } else {
            element.className = '';
            element.tabIndex = -1;
            element.setAttribute("contenteditable", false);
        }
    };

    // Initialize
    element.raContentEditableInitialize(type);

    // Update the element html when the value is changed by javascript code.
    effect(() => {
        getValue(value => {
            if (value === element.innerHTML) {
                return;
            }

            element.innerHTML = value;
            togglePlaceholder();
        });
    });

    // Cleanup all event listeners when the field is removed from the DOM.
    cleanup(() => {
        element.removeEventListener('focus', focus);
        element.removeEventListener('input', input);
        element.removeEventListener('blur', blur);
        element.removeEventListener('paste', paste);
        element.removeEventListener('keydown', keydown);
    });
});
