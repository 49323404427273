import Alpine from 'alpinejs';
import jQuery from 'jquery';
import callDotNet from "../blazor/callDotNet.js";
import delay from "../utilities/delay.js";
import isElementInView from "../utilities/isElementInView.js";
import updateAlpineModel from "../blazor/updateAlpineModel.js";
import debounce from '../utilities/debounce.js';

Alpine.directive('autocomplete', (element, { expression }, { cleanup, effect, evaluateLater }) => {
    const getValue = evaluateLater("value");
    const getPlaceholder = evaluateLater("placeholder");
    let skipPopupOpen = false;

    const isInput = element.localName.toLowerCase() === 'input';

    /**
     * Removes the placeholder styling, so it looks like a field with a value.
     */
    const removePlaceholder = () => {
        getValue(value => {
            element.classList.remove("text-neutral-400", "italic", "text-sm");
            element.innerHTML = value || '';
        });
    };

    /**
     * If the field has a value, this method will remove the placeholder styling.
     * If the field does not have a value, this method will add a placeholder styling and show the placeholder label.
     */
    const togglePlaceholder = () => {
        getValue(value => {
            if (value) {
                removePlaceholder();
                return;
            }

            getPlaceholder(placeholder => {
                if (!placeholder) {
                    return;
                }

                if (isInput) {
                    element.placeholder = placeholder;
                } else {
                    element.classList.add("text-neutral-400", "italic", "text-sm");
                    element.innerHTML = placeholder;
                }
            });
        });
    };

    /**
     * When a click is detected outside the field or the popup, we want to close the popup and blur the field.
     */
    const bodyClick = (event) => {
        const $parent = jQuery(element).parent();
        if ($parent.find(event.target).length === 0) {
            closePopup();
            blur();
        }
    };

    /**
     * When the mouse moves over the popup, we want to highlight the list item that the mouse is over.
     */
    const popupMouseMove = (event) => {
        const li = jQuery(event.target).closest('li').get(0);
        if (li) {
            const index = jQuery(element).siblings('ul').children('li').index(li);
            highlightOption(index);
        }
    };

    /**
     * When we click on the popup, we want to select the option that was clicked on.
     * We ensure than on a click, we close the menu and keep focus on the field for easy tabbing.
     */
    const popupClick = async (event) => {
        const li = jQuery(event.target).closest('li').get(0);
        if (li) {
            const index = jQuery(element).siblings('ul').children('li').index(li);
            selectOption(index);
            skipPopupOpen = true;
            element.focus();
        }
    };

    /**
     * Gets a value indicating whether the option popup menu is opened.
     */
    const isPopupOpened = () => {
        return jQuery(element).siblings('ul').length > 0;
    };

    /**
     * Opens the options popup. The options are gotten from a Blazor method.
     */
    const openPopup = async () => {
        const options = await callDotNet(element, "GetOptions");
        if (options.length === 0) {
            return;
        }

        if (isPopupOpened()) {
            return;
        }

        closePopup();
        if (skipPopupOpen) {
            skipPopupOpen = false;
            return;
        }

        let popup = `<ul class="absolute top-full bg-white border border-neutral-200/70 focus:outline-none left-[20px] max-h-[240px] mt-[2px] overflow-auto py-1 ring-1 ring-black ring-opacity-5 rounded-b-lg rounded-md shadow-md text-neutral-700 text-sm w-40 z-50">`;
        for (let index = 0; index < options.length; index++) {
            const option = options[index];

            popup += `
                <li class="cursor-pointer duration-200 flex flex-wrap gap-x-2 h-full items-center px-3 py-2 relative select-none text-gray-700 transition-colors"
                    data-value="${option.value}"
                    data-label="${option.label.toLowerCase()}">
                    <span class="font-medium truncate">
                        ${option.label}
                    </span>
                    ${option.extraContent || ''}
                </li>`;
        }

        popup += `</ul>`;

        const $element = jQuery(element);
        $element.after(popup);

        await delay();

        const ul = $element.siblings('ul').get(0);
        ul.addEventListener('mousemove', popupMouseMove);
        ul.addEventListener('click', popupClick);

        const parent = $element.parent()[0];
        const selectDropdownBottomPos = parent.getBoundingClientRect().top + parent.offsetHeight + 240;
        if (window.innerHeight < selectDropdownBottomPos) {
            const popupElement = $element.parent().children('ul').get(0);
            popupElement.classList.add('bottom-full');
            popupElement.classList.remove('top-full');
        }

        window.addEventListener('click', bodyClick);
        sortOptions();
    };
    const openPopupDebounce = debounce(openPopup, 100);

    /**
     * Closes the option popup.
     */
    const closePopup = () => {
        const $element = jQuery(element);
        const $ul = $element.siblings('ul');

        $ul.each((index, element) => {
            element.removeEventListener('mousemove', popupMouseMove);
            element.removeEventListener('click', popupClick);
        });
        $ul.remove();

        window.removeEventListener('click', bodyClick);
    };

    /**
     * Gets the currently highlighted option index.
     */
    const getHighlightedOptionIndex = () => {
        const $ul = jQuery(element).siblings('ul');
        return $ul.children('li').index($ul.children('li.bg-neutral-100'));
    };

    /**
     * Highlights an option based on an index.
     */
    const highlightOption = (index) => {
        const $ul = jQuery(element).siblings('ul');
        const $items = $ul.find('li');
        if (index >= $items.length) {
            index = $items.length - 1;
        }

        $ul.children('li.bg-neutral-100').removeClass(['bg-neutral-100', 'text-gray-900']);
        if (index < 0) {
            return;
        }

        $items.eq(index).addClass(['bg-neutral-100', 'text-gray-900']);
        const ul = $ul.get(0);
        const highlightedLi = $items.get(index);
        if (ul && highlightedLi) {
            const visible = isElementInView(highlightedLi, ul);
            if (!visible) {
                const newScrollPos = (highlightedLi.offsetTop + highlightedLi.offsetHeight) - ul.offsetHeight;
                ul.scrollTop = newScrollPos > 0 ? newScrollPos : 0;
            }
        }
    };

    /**
     * Selects an option from its index. Selecting an option will set the field value to that option and close the popup.
     */
    const selectOption = (index) => {
        const $ul = jQuery(element).siblings('ul');
        const $item = $ul.children('li').eq(index);
        if (!$item) {
            return;
        }

        updateAlpineModel(element, $item.data('value').toString());
        closePopup();
    };

    /**
     * Sorts the options inside the popup with the current value.
     */
    const sortOptions = () => {
        getValue(async (value) => {
            const search = value.toLowerCase();
            const $ul = jQuery(element).siblings('ul');
            const $items = $ul.children('li');
            let hasMatch = false;

            const sortedItems = $items.toArray().sort(function (a, b) {
                const aValue = $(a).data('label').toString();
                const bValue = $(b).data('label').toString();
                if (search) {
                    const aStartsWith = aValue.startsWith(search);
                    const bStartsWith = bValue.startsWith(search);
                    if (aStartsWith && bStartsWith) {
                        hasMatch = true;
                        return aValue > bValue ? 1 : -1;
                    } else if (aStartsWith) {
                        hasMatch = true;
                        return -1;
                    } else if (bStartsWith) {
                        hasMatch = true;
                        return 1;
                    }

                    const aIndexOf = aValue.indexOf(search);
                    const bIndexOf = bValue.indexOf(search);
                    if (aIndexOf !== -1 && bIndexOf !== -1) {
                        hasMatch = true;
                        return aIndexOf > bIndexOf ? 1 : -1;
                    } else if (aIndexOf !== -1) {
                        hasMatch = true;
                        return -1;
                    } else if (bIndexOf !== -1) {
                        hasMatch = true;
                        return 1;
                    }
                }

                return aValue > bValue ? 1 : -1;
            });

            $items.detach();
            $ul.append(sortedItems);
            await delay();
            highlightOption(hasMatch ? 0 : -1);
        });
    };

    /**
     * When an input happens on the element, we need to make sure that the underlying alpine model is updated.
     * For autocomplete, we also sort the list of options.
     */
    const input = () => {
        updateAlpineModel(element, isInput ? element.value : element.innerHTML);
        sortOptions();
    };

    /**
     * Custom implementation of the paste event to remove any <span> and other HTML elements. that would be included in the default implementation of the paste.
     */
    const paste = (event) => {
        event.preventDefault();

        const paste = (event.clipboardData || window.clipboardData).getData("text");
        const selection = window.getSelection();
        if (!selection.rangeCount) return;
        selection.deleteFromDocument();
        selection.getRangeAt(0).insertNode(document.createTextNode(paste));
        selection.collapseToEnd();

        element.dispatchEvent(new Event('input'));
    };

    /**
     * Handles keydown events for the autocomplete component.
     * Tab - Close the popup and moves to the next field.
     * ArrowUp - Highlight the previous option.
     * ArrowDown - Highlight the next option.
     * Enter - Select the currently highlighted option. If the popup is closed, blurs the field.
     * @param event
     */
    const keydown = (event) => {
        if (event.key === "Tab") {
            closePopup();
            blur();
        }

        if (event.key === 'ArrowUp') {
            event.preventDefault();
            const index = getHighlightedOptionIndex();
            highlightOption(index >= 1 ? index - 1 : 0);
        }

        if (event.key === 'ArrowDown') {
            event.preventDefault();
            const index = getHighlightedOptionIndex();
            highlightOption(index >= 0 ? index + 1 : 0);
        }

        if (event.key === "Enter") {
            if (isPopupOpened()) {
                const index = getHighlightedOptionIndex();
                if (index !== -1) {
                    selectOption(getHighlightedOptionIndex());
                } else {
                    closePopup();
                }
            } else {
                element.blur();
            }
            event.preventDefault();
        }

        event.stopPropagation();
    };

    /**
     * In the event that the popup is closed and the field is still focused, we want the popup to reopen on a click.
     */
    const click = () => {
        openPopupDebounce();
    };

    /**
     * When the field is blurred, we want to remove all attached event handlers in an attempt to keep the page as light as possible.
     * We also want to notify Blazor that the field is blurred and passing the final field value.
     */
    const blur = () => {
        element.style.minWidth = null;
        togglePlaceholder();

        element.removeEventListener('blur', blur);
        element.removeEventListener('input', input);
        element.removeEventListener('paste', paste);
        element.removeEventListener('click', click);
        element.removeEventListener('keydown', keydown);

        if (!isPopupOpened()) {
            getValue(value => {
                callDotNet(element, "OnBlur", value);
            });
        }
    };

    /**
     * Attach events to the field and removes the placeholder styling.
     * Also opens the options menu for the autocomplete.
     */
    const focus = () => {
        const rect = element.getBoundingClientRect();
        element.style.minWidth = rect.width + 'px';
        removePlaceholder();

        element.addEventListener('blur', blur);
        element.addEventListener('input', input);
        element.addEventListener('paste', paste);
        element.addEventListener('click', click);
        element.addEventListener('keydown', keydown);

        openPopupDebounce();
    };

    updateAlpineModel(element, isInput ? element.value : element.innerHTML);
    togglePlaceholder();

    // Only add event listeners if the field should be editable.
    if (expression !== 'false') {
        element.setAttribute("contenteditable", true);
        element.addEventListener('focus', focus);
    }

    // Update the element html when the value is changed by javascript code.
    effect(() => {
        getValue(value => {
            if (isInput) {
                if (value === element.value) {
                    return;
                }

                element.value = value;
                togglePlaceholder();
                return;
            }

            if (value === element.innerHTML) {
                return;
            }

            element.innerHTML = value;
            togglePlaceholder();
        });
    });

    // Cleanup all event listeners when the field is removed from the DOM.
    cleanup(() => {
        closePopup();
        element.removeEventListener('focus', focus);
        element.removeEventListener('blur', blur);
        element.removeEventListener('input', input);
        element.removeEventListener('paste', paste);
        element.removeEventListener('click', click);
        element.removeEventListener('keydown', keydown);
    });
});
