import Alpine from 'alpinejs';
import jQuery from 'jquery';
import focus from '@alpinejs/focus'
import './alpine/autocomplete.js';
import './alpine/contenteditable.js';
import './alpine/quill.js';
import assignDotNetReference from "./blazor/assignDotNetReference.js";
import callDotNet from "./blazor/callDotNet.js";
import callMethodOnElement from "./blazor/callMethodOnElement.js";
import triggerEvent from "./blazor/triggerEvent.js";
import updateAlpineModel from "./blazor/updateAlpineModel.js";
import updateElementHtml from "./blazor/updateElementHtml.js";
import * as keybind from './events/keybind.js';
import debounce from "./utilities/debounce.js";
import delay from "./utilities/delay.js";
import findClosestScrollContainer from "./utilities/findClosestScrollContainer.js";
import isElementInView from "./utilities/isElementInView.js";
import scrollToId from "./utilities/scrollToId.js";

window.$ = jQuery;
window.jQuery = jQuery;

Alpine.plugin(focus);
Alpine.start();
window.Alpine = Alpine;

window.ra = window.ra || {};

// Blazor
window.ra.assignDotNetReference = assignDotNetReference;
window.ra.callDotNet = callDotNet;
window.ra.callMethodOnElement = callMethodOnElement;
window.ra.triggerEvent = triggerEvent;
window.ra.updateAlpineModel = updateAlpineModel;
window.ra.updateElementHtml = updateElementHtml;

// Events
window.ra.listenToKeybind = keybind.listenToKeybind;
window.ra.unlistenToKeybind = keybind.unlistenToKeybind;

// Utilities
window.ra.debounce = debounce;
window.ra.delay = delay;
window.ra.findClosestScrollContainer = findClosestScrollContainer;
window.ra.isElementInView = isElementInView;
window.ra.scrollToId = scrollToId;
